<template>
  <q-select
    v-model="cost_centre"
    :options="availableCostCentres"
    :label="$t('rac.cost_centre')"
    :error="error"
    :error-message="errorMessage"
    v-bind="$attrs"
  />
</template>

<script>
import { cost_centres } from 'api/onwardtravel.js'
export default {
  name: 'MCostCentre',
  props: {
    value: String,
    slug: String,
    error: Boolean,
    errorMessage: String
  },
  data () {
    return {
      availableCostCentres: []
    }
  },
  computed: {
    cost_centre: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  watch: {
    slug: 'fetchCostCentres'
  },
  mounted () {
    this.fetchCostCentres()
  },
  methods: {
    async fetchCostCentres () {
      const org = this.$store.getters.authentication.organisation
      if (org && org.cost_centres) {
        this.availableCostCentres = [...org.cost_centres]
      }

      const orgSlug = this.slug || (org ? org.slug : null)

      if (orgSlug) {
        const { data } = await cost_centres(orgSlug)
        const centres = data[0] || ['N/A']
        this.availableCostCentres = [...new Set([...this.availableCostCentres, ...centres])]
      }
    }
  }
}
</script>
